<template>
  <b-row>
    <b-col col="12" md="6" class="mx-auto">
      <b-card title="User Wallet Details">
        <b-alert :show="message != ''" variant="success" class="p-1">{{ message }}</b-alert>
        <b-alert :show="alert != ''" variant="danger" class="p-1">{{ alert }}</b-alert>
        <b-row>
          <b-col col="12" md="6" class="mb-1">
            <label for="username">Username</label>
            <b-form-input id="username" v-model="walletDetails.username" :disabled="true" />
          </b-col>
          <b-col col="12" md="6" class="mb-1">
            <label for="userType">User Type</label>
            <b-form-input id="userType" v-model="walletDetails.userType" :disabled="true" />
          </b-col>
          <b-col col="6" md="4" class="mb-1">
            <label for="pendingPoints">Pending Points</label>
            <b-form-input
              id="pendingPoints"
              v-model="walletDetails.pendingPoints"
              :disabled="true"
            />
          </b-col>

          <b-col col="6" md="4" class="mb-1">
            <label for="points">Earned Points</label>
            <b-form-input id="points" v-model="walletDetails.points" :disabled="true" />
          </b-col>

          <b-col col="6" md="4" class="mb-1">
            <label for="rewardActivated">Reward activated?</label>
            <b-form-input id="points" v-model="walletDetails.rewardActivated" :disabled="true" />
          </b-col>

          <b-col col="12" md="4" class="mb-1">
            <label for="HashSubmitted">HashSubmitted?</label>
            <b-form-input
              id="HashSubmitted"
              v-model="walletDetails.wallet.hashSubmitted"
              :disabled="true"
            />
          </b-col>
          <b-col col="12" md="12" class="mb-1">
            <label for="rewardTrx">Reward Transaction # (TRX)</label>
            <b-form-input
              id="rewardTrx"
              v-model="walletDetails.wallet.rewardTrx"
              :disabled="true"
            />
          </b-col>
          <b-col col="12" md="12" class="mb-1">
            <label for="requestedWallet">Wallet Address</label>
            <b-form-input
              id="requestedWallet"
              v-model="walletDetails.wallet.requestedWallet"
              :disabled="true"
            />
          </b-col>
          <b-col col="12" md="12" class="mb-1">
            <b-button
              variant="primary"
              block
              @click="activateEarnings"
              :disabled="
                isLoading ||
                walletDetails.wallet.hashSubmitted == false ||
                walletDetails.rewardActivated == true
              "
              >{{ isLoading ? "Please wait" : "Activate Rewards" }}</b-button
            >
            <b-alert
              class="mt-2 p-1"
              variant="danger"
              :show="!isLoading && walletDetails.wallet.hashSubmitted == false"
              >You cannot activate rewards because this user did not provided the transfer
              infomration</b-alert
            >

            <b-alert show v-if="walletDetails.rewardActivated" variant="warning" class="mt-2 p-1"
              >This user rewards already activated</b-alert
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axious from "axios";
import {
  BRow,
  BCol,
  BCard,
  BSkeletonWrapper,
  BSkeleton,
  BFormInput,
  BButton,
  BAlert,
} from "bootstrap-vue";
export default {
  data() {
    return {
      isLoading: false,
      userId: this.$route.params.id,
      walletDetails: {},
      formData: {},
      alert: "",
      message: "",
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BSkeletonWrapper,
    BSkeleton,
    BButton,
    BFormInput,
    BAlert,
  },
  mounted() {
    this.getWalletDetails();
  },
  methods: {
    async activateEarnings() {
      this.isLoading = true;
      try {
        const response = await axious.post(`/user/activateEarnings/${this.userId}`);
        this.message = response.data.message;
      } catch (error) {
        this.alert = error.response.data.message || "Something went wrong";
      } finally {
        this.isLoading = false;
        this.getWalletDetails();
      }
    },
    async getWalletDetails() {
      this.isLoading = true;
      try {
        const response = await axious.get(`/user/walletDetails/${this.userId}`);
        this.walletDetails = response.data.data;

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
  },
};
</script>
